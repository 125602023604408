export const SE = {
  UnexpectedError: "Något gick snett :( Försök ladda om sidan",

  CURRENT_LANGUAGE: "Svenska",

  Claims_Administration: "Administration",
  Claims_Energy: "Energi",
  "Claims_Extend role to all organizations":
    "Utöka roll till alla organisationer",
  Claims_Invoices: "Fakturor",
  Claims_Knowledge: "Kunskapscentrum",
  Claims_NetMW: "NetMW",
  Claims_PortfolioPhysical: "Portfolio Fysisk",
  Claims_PortfolioFinancial: "Portfolio Finansiell",
  Claims_Developer: "Utvecklare",
  Claims_Documents: "Dokument",
  Claims_InvoiceMaterials: "Fakturaunderlag",
  Claims_DocumentsForAll: "Dokument för alla organisationer",
  Claims_Flexibility: "Flexibilitet",

  Contract: "Kontrakt",
  Counterpart: "Motpart",
  Courtage: "Handelsavgift",
  Comment: "Kommentar",
  Contact: "Kontakt",
  "Created by": "Skapad av",
  Created: "Skapad",
  "Expiry date": "Förfallodatum",
  EXPIRED: "UTGÅNGET",

  "You have no api keys.": "Du har inga api nycklar.",
  "Error: missing or faulty headers: ":
    "Fel: saknade eller felaktiga rubriker: ",
  "Error: missing or faulty data: ": "Fel: saknade eller felaktiga data: ",
  "Creating import position failed":
    "Skapande av import positioner misslyckades",
  "Removing import position was successful": "Importerad position togs bort",
  "Removing import position failed":
    "Misslyckades att ta bort importerad position",
  Paste: "Klistra in",
  Reset: "Återställ",
  "Paste not supported by browser, use CTRL+V":
    "Klistra in stöds ej av webbläsaren, använd CTRL+V",
  "Could not fetch coordinates for plants":
    "Kunde ej hämta koordinater för anläggningarna",

  hedgeVolume: "Säkrad volym",
  Forecast: "Prognos",
  mandateMinVolume: "MIN",
  mandateMaxVolume: "MAX",
  "Without imported position": "Utan importerad position",
  "With imported position": "Med importerad position",
  "Only imported position": "Endast importerad position",

  January: "januari",
  February: "februari",
  March: "mars",
  April: "april",
  May: "maj",
  June: "juni",
  July: "juli",
  August: "augusti",
  September: "september",
  October: "oktober",
  November: "november",
  December: "december",

  All: "Allt",
  Videos: "Filmer",
  Articles: "Artiklar",
  Webinars: "Webbinarium",

  "My bookmarks": "Mina bokmärken",
  Bookmark: "Bokmärke",

  Loading: "Hämtar",
  "No articles available": "Inga artiklar hittades",

  Users: "Användare",
  "User Roles": "Användarroller",
  Roles: "Roller",
  "Users & Roles": "Användare & Roller",
  Developer: "Utvecklare",
  "Edit Profile": "Hantera profil",
  Logout: "Logga ut",
  "User administration": "Hantera användare",
  "Roles administration": "Hantera roller",

  Cancel: "Avbryt",
  Close: "Stäng",
  Done: "Klar",
  Add: "Lägg till",
  "Save Changes": "Spara ändringar",
  Save: "Spara",

  "User added!": "Användare tillagd!",
  "User updated": "Användare uppdaterad",
  "User update failed": "Uppdatering av användare misslyckades",
  "Filter by name": "Filtrera på namn",
  "Add new user": "Lägg till användare",
  "Users in": "Användare i",

  "Add new plant": "Lägg till anläggning",
  "Edit plant": "Redigera anläggning",
  "Price area": "Prisområde",
  "Price areas": "Prisområden",
  "Meteologica Id": "Meteologica Id",

  "Ice": "Is",
  "Curtailment": "Begränsning",
  "Power": "Effekt",
  "Maximum power": "Max effekt",

  "Coordinates (latitude)": "Koordinater (latitud)",
  "Coordinates (longitude)": "Koordinater (longitud)",

  "User Roles in": "Användarroller i",
  "Search by role": "Sök efter roll",
  "Add new role": "Skapa ny roll",

  "Add new client": "Lägg till kund",
  Clients: "Kunder",
  Client: "Kund",
  "Organization number": "Organisationsnummer",
  "NAV ID": "NAV ID",
  "BFUS ID": "BFUS ID",
  "EDIEL ID": "EDIEL ID",

  "Edit client": "Redigera kund",
  "Search client": "Sök efter kund",
  "Client type": "Kundtyp",

  "Search by name": "Sök efter namn",

  Welcome: "Välkommen",
  "Welcome to Modity": "Välkommen till Modity",
  "A simple energy trade": "En enkel energiaffär",
  "Contact us at": "Kontakta oss på",

  Home: "Hem",
  "Knowledge Center": "Kunskapscentrum",
  "Read entry": "Läs inlägg",
  "Everything you need to know": "Allt du behöver veta, helt enkelt",
  "Search results for:": "Sökresultat för:",
  "Showing:": "Visar:",
  "Looking for anything particular?": "Letar du efter något särskilt?",
  Search: "Sök",
  Invoices: "Fakturor",
  "All invoices in one place": "Alla fakturor samlade på ett ställe",
  "Latest news": "Senaste nyheter",
  "See all news": "Se alla nyheter",
  "There are no news yet": "Det finns inga nyheter än",
  "Latest invoices": "Senaste fakturor",
  "See all invoices": "Se alla fakturor",
  "Search by invoice number": "Sök efter fakturanummer",
  "No invoices found": "Inga fakturor hittades",

  Email: "E-post",
  "First name": "Förnamn",
  "Last name": "Efternamn",
  "Phone number": "Telefonnummer",

  "Daily comments": "Dagens kommentar",
  "Subscribed to daily comments": "Prenumererar på dagens kommentar",
  "Edit profile": "Redigera profil",
  Role: "Roll",

  User: "Användare",
  Name: "Namn",
  "Assigned role": "Tilldelad roll",
  Delete: "Ta bort",
  Remove: "Ta bort",
  "Last active": "Senast aktiv",

  Edit: "Redigera",
  "Role name": "Namn på roll",
  "What access rights should this role provide":
    "Vilka behörigheter skall den här rollen ge",
  "Role is used by": "Rollen används av",
  "Add role": "Lägg till roll",
  "Role updated": "Roll uppdaterad",
  "Role update failed": "Rolluppdatering misslyckades",
  "Role added": "Roll tillagd",
  "Adding role failed": "Tilläggning av roll misslyckades",
  "Remove role": "Ta bort roll",
  "Role deleted": "Roll borttagen",
  "Failed to remove role": "Borttagning av roll misslyckades",

  "Remove user": "Ta bort användare",
  "Remove from organization": "Ta bort från organisation",
  "Are you sure you want to remove": "Är du säker på att du vill ta bort",
  "was removed": "togs bort",
  "User added": "Användare tillagd",
  "Adding user failed": "Det gick inte att lägga till användaren",
  "User deleted": "Användare borttagen",
  "Failed to remove user": "Det gick inte att ta bort användaren",
  "Remove from all organizations": "Ta bort från alla organisationer",

  "No data found": "Ingen data tillgänglig",
  "You are not part of any organization, contact your administrator for help.":
    "Du tillhör ingen organisation, kontakta din administratör för hjälp.",

  "Dont show again": "Visa inte igen",
  Dismiss: "Avfärda",
  "Read more": "Läs mer",

  "Updating your profile failed": "Misslyckades att uppdatera din profil",

  "Sorry, couldn't find what you were looking for":
    "Ursäkta, vi kunde inte hitta det du letade efter",
  Please: "Vänligen",
  refresh: "uppdatera",
  or: "eller",
  "go back": "gå tillbaka",

  "Load more": "Visa fler",
  "select image": "välj bild",

  Heading_ProductionConsumption: "Förbrukning och produktion",
  Heading_SpotPrice: "Spotpris",
  Overview: "Översikt",
  Plant: "Anläggning",
  Plants: "Anläggningar",
  Planning: "Planering",

  "Preliminary production": "Preliminär produktion",
  "Preliminary consumption": "Preliminär förbrukning",
  "Production total": "Produktion total",
  "Consumption total": "Förbrukning total",
  "Market data": "Marknadsdata",
  "Nordic System Price": "Nordiskt systempris",
  "Cannot get Production": "Produktionsdata kan ej hämtas",
  "Cannot get Consumption": "Förbrukningsdata kan ej hämtas",
  "Cannot get Market data": "Marknadsdata kan ej hämtas",
  Production: "Produktion",
  Consumption: "Förbrukning",
  "Consumption and Production": "Förbrukning och produktion",
  PlanConsumption: "Planerad förbrukning",
  PlanProduction: "Planerad produktion",
  Interval: "Intervall",

  Select: "Välj",
  "available selections": "tillgängliga val",
  "Select max": "Välj max",

  Time: "Tid",
  Yes: "Ja",

  "ALL FACILITIES DURING PERIOD": "ALLA ANLÄGGNINGAR UNDER PERIODEN",
  "ALL PLANTS DURING PERIOD": "ALLA ANLÄGGNINGAR UNDER PERIODEN",
  "ALL REGIONS DURING PERIOD": "ALLA SNITTOMRÅDEN UNDER PERIODEN",
  "YEAR OVERVIEW FOR": "ÅRSÖVERSIKT FÖR",
  PLANT: "ANLÄGGNING",
  PERIOD: "PERIOD",
  "PRICE OUTCOME": "PRISUTFALL",
  "Weather information": "Väderinformation",
  "Meter points": "Mätpunkter",

  Temperature: "Temperatur",
  Source: "Källa",
  "Temperature data from": "Temperaturdata från",
  "No temperature station found": "Ingen temperaturstation hittades",
  "No temperature data found": "Ingen temperaturdata hittades",
  "Wind data from": "Vinddata från",
  "No wind data found": "Ingen vinddata hittades",
  "No wind station found": "Ingen vindstation hittades",
  "No data available for the selected period.":
    "Ingen data tillgänglig för den valda perioden",

  "Select plant": "Välj anläggning",
  "Please select a plant": "Välj en anläggning för att se mer data",
  "Getting list of plants": "Hämtar anläggningar",
  "No plants found": "Inga anläggningar hittades",
  From: "Från",
  To: "Till",
  "To (inclusive)": "Till och med",
  "Show previous months": "Visa föregående månader",
  "ALL MEASURE POINTS": "ALLA MÄTPUNKTER",

  Menu_Start: "Hem",
  Menu_Files: "Filer",
  Menu_Invoices: "Fakturor",
  Menu_Knowledge: "Kunskapscentrum",
  Menu_Energy: "Förbrukning & produktion",
  Menu_Settings: "Inställningar",
  Menu_ModitySettings: "Modity-inställningar",
  Menu_NetMW: "Net MW",
  Menu_Portfolio: "Portfölj",
  Menu_Developer: "Utvecklare",

  Turbines: "Turbiner",
  "% of max effect": "% av max effekt",
  Period: "Period",

  "Profiled consumption": "Månadsmätt förbrukning",
  "Metered consumption": "Timmätt förbrukning",

  "Show all plants": "Visa alla anläggningar",
  "Show all measurands": "Visa alla mätpunkter",
  "Show all turbines": "Visa alla turbiner",
  "Profiled production": "Månadsmätt produktion",
  "Metered production": "Timmätt produktion",
  AND: "OCH",

  HourlyStandardsProduction: "Produktion",
  HourlyStandardsConsumption: "Förbrukning",

  productionHourlyPriceArea1: "SE1",
  productionHourlyPriceArea2: "SE2",
  productionHourlyPriceArea3: "SE3",
  productionHourlyPriceArea4: "SE4",
  consumptionHourlyPriceArea1: "SE1",
  consumptionHourlyPriceArea2: "SE2",
  consumptionHourlyPriceArea3: "SE3",
  consumptionHourlyPriceArea4: "SE4",

  consumptionMonthly: "Månadsmätt förbrukning",
  MonthlyStandards: "Månadsmätt förbrukning",
  monthlyConsumptionStandard: "Månadsmätt förbrukning",
  hourlyConsumptionStandard: "Timmätt förbrukning",

  "File is too large, has to be maximum 195kB":
    "Filen är för stor, största tillåtna storlek är 195kB",

  "Invalid email": "Inkorrekt e-post: Ange bara kontonamn i fältet",
  "This field is required": "Detta fält krävs",
  "Maximum 64 characters": "Max 64 tecken",
  "Numbers only": "Endast siffror",
  "The name already exists, a unique name is required":
    "Namnet finns redan, ett unikt namn krävs",
  "Set meteologica id": "Ange meteologica id",

  supplier: "Leverantör",
  balanceController: "Balansansvarig",

  "Add turbine": "Lägg till turbin",
  "Add new turbine": "Lägg till turbin",
  "Edit turbine": "Redigera turbin",
  "Search by turbine": "Sök efter turbin",
  Unit: "Enhet",

  Measurands: "Mätpunkter",
  "Add measurand": "Lägg till mätpunkt",
  "Add new measurand": "Lägg till mätpunkt",
  "Edit measurand": "Redigera mätpunkt",
  "Search by measurand": "Sök efter mätpunkt",
  Type: "Typ",
  "Invalid ID": "Värdet är inte ett giltigt ID",

  "Measurand added": "Mätpunkt tillagd",
  "Adding measurand failed": "Det gick inte att lägga till mätpunkten",
  "Measurand deleted": "Mätpunkt borttagen",
  "Deleting measurand failed": "Det gick inte att ta bort mätpunkten",
  "Measurand updated": "Mätpunkt uppdaterad",
  "Updating measurand failed": "Det gick inte att uppdatera mätpunkten",

  "Turbine added": "Turbin tillagd",
  "Adding turbine failed": "Det gick inte att lägga till turbinen",
  "Turbine deleted": "Turbin borttagen",
  "Deleting turbine failed": "Det gick inte att ta bort turbinen",
  "Turbine updated": "Turbin uppdaterad",
  "Updating turbine failed": "Det gick inte att uppdatera turbinen",

  "Plant added": "Anläggning tillagd",
  "Adding plant failed": "Det gick inte att lägga till anläggningen",
  "Plant deleted": "Anläggning borttagen",
  "Deleting plant failed": "Det gick inte att ta bort anläggningen",
  "Plant updated": "Anläggning uppdaterad",
  "Updating plant failed": "Det gick inte att uppdatera anläggningen",
  "Cannot delete plant with either measurands or turbines":
    "Kan inte ta bort anläggningen med turbiner eller mätpunkter",

  "Organization added": "Kunden tillagd",
  "Adding organization failed": "Det gick inte att lägga till kunden",
  "Organization deleted": "Kunden borttagen",
  "Deleting organization failed": "Det gick inte att ta bort kunden",
  "Organization updated": "Kunden uppdaterad",
  "Updating organization failed": "Det gick inte att uppdatera kunden",

  Effect: "Effekt",
  "Sum of max effects from all turbines":
    "Alla turbiners summerade maxeffekter",
  "Unit for maximum effect": "Max effekt enhet",

  "Portfolio report": "Portföljrapport",
  "Import Positions": "Importera positioner",
  "Import ELC Positions": "Importera ELC positioner",
  "Import GO Positions": "Importera UG positioner",

  TABS_TOTAL: "Total",
  TABS_PHYSICAL: "Fysisk",
  TABS_FINANCIAL: "Finansiell",
  Total: "Total",
  Physical: "Fysisk",

  TOTAL_TABLE_TITLE_PHYSICAL: "Fysiskt",
  TOTAL_TABLE_TITLE_FINANCIAL: "Finansiellt",
  Financial: "Finansiell",
  Month: "Månad",
  Volume: "Volym",
  "Cost(-)/Revenue(+)": "Kostnad(-)/Intäkt(+)",
  Fees: "Avgifter",
  "Hedged volume": "Säkrad volym",
  "Trading fees": "Handelsavgifter",
  "Price per unit": "Á pris",
  "öre/kWh": "öre/kWh",
  TOTAL_TABLE_TOTAL: "Totalt",

  Planned: "Planerad",
  Outcome: "Utfall",
  Measured: "Uppmätt",
  Imbalance: "Obalans",
  Sum: "Summa",
  Fee: "Avgift",

  Price: "Pris",
  price: "Pris",
  Currency: "Valuta",

  SUBPAGE_OUTCOME: "Utfall",
  SUBPAGE_HEDGE_REPORT: "Hedgerapport",
  SUBPAGE_INDEX_POOL: "Indexpool",
  SUBPAGE_TRANSACTION_LIST: "Transaktionslista",
  SUBPAGE_INDEX_DETAILS: "Indexdetaljer",
  SUBPAGE_GREEN_PRODUCTS: "Miljöprodukter",

  Product: "Produkt",
  "Hedged price": "Säkrat pris",
  "Calculated result for": "Avräknat resultat",
  "Summary of trading fees for": "Handelsavgifter sammanställning",
  "Purchased volume": "Handlad volym",
  "Fees (NASDAQ)": "Avgifter (NASDAQ)",
  "Download report": "Hämta rapport",

  "Open volume": "Öppen volym",
  "Market price": "Marknadspris",
  "Forecast price": "Prognospris",
  "% Hedged": "% Säkrad",
  "% Min": "% Min",
  "% Max": "% Max",
  "Go Volume": "UG Volym",
  "Go Agreement Price": "UG Pris ",
  "Elc Volume": "ELC Volym",
  "Elc Agreement Price": "ELC Pris",
  "Total Agreement Price": "Totalt säkrat pris",

  hedge: "Hedge",
  targetVolume: "Målvolym",
  utilizedVolume: "Förbrukning och produktion",
  Order: "Typ",
  "Trade date": "Handelsdatum",
  Settlement: "Utfall",
  "Start date": "Start",
  "End date": "Slut",
  Comment1: "Kommentar 1",
  Comment2: "Kommentar 2",
  ID: "ID",
  Status: "Status",

  "Show/Hide columns": "Visa/Dölj kolumner",
  "Visible columns": "Synliga kolumner",

  "Actual produced or consumed volume":
    "faktisk producerad eller förbrukad volym",
  "With imbalance price": "inkl obalanskostnad",
  "Trading fees and government fees": "handels- och myndighetsavgifter",

  Updated: "Uppdaterad",
  Contacts: "Kontaktpersoner",
  "Contact us": "Kontakta oss",
  "The team, which helps you simplify your energy business":
    "Teamet, som hjälper dig att förenkla dina energiaffärer",
  "Other matters": "Övriga ärenden",
  "Contact added": "Kontakt tillagd",
  "Adding contact failed": "Kontakten kunde inte läggas till",
  "Contact updated": "Kontakt uppdaterad",
  "Remove contact": "Ta bort kontakt",
  "Contact removed": "Kontaktperson borttagen",
  "Contact update failed": "Kontakten kunde inte uppdateras",
  "Contact deleted": "Kontakt borttagen",
  "Failed to remove contact": "Misslyckades med att ta bort kontakt",
  "Add new contact person": "Lägg till ny kontaktperson",
  Function: "Funktion",
  "Select a function": "Välj en funktion",
  People: "Personer",
  "Edit contact person": "Redigera kontaktperson",
  "Select a person": "Välj en person",

  SINGULAR_SELECTED: "vald",
  MULTIPLE_SELECTED: "valda",

  "Analysis Manager": "Analysansvarig",
  "Head of Business Area Gas": "Ansvarig affärsområde gas",
  "Head of front office and Business Area Trading":
    "Chef Front Office och affärsområde trading",
  "Physical Trading Manager": "Chef Fysisk Handel",
  "Head of Middle Office and Business Area Power":
    "Chef Middle Office och affärsområde el",
  "Chief Disruption Officer": "Chief Disruption Officer",
  "Chief Financial Officer": "Chief Financial Officer",
  "Compliance Officer": "Compliance Officer",
  "Physical Settlement": "Ekonom fysisk avräkning",
  "Financial Manager": "Ekonomichef",
  "Financial Clearing and Settlement": "Finansiell avräkning",
  "Financial Trader": "Finansiell Handlare",
  "Physical Trader": "Fysisk Handlare",
  "HR Manager": "HR-chef",
  "IT Manager": "IT-ansvarig",
  "Key Account Manager": "Key Account Manager",
  "Communications Manager": "Kommunikationschef",
  "Communications Officer": "Kommunikatör",
  "Facility Assistant": "Kontorsansvarig",
  "Key Account Manager (Partner)": "Kundansvarig Partner - Key Account Manager",
  "Quantitative Developer": "Kvantitativ utvecklare",
  "Lead Architect": "Lead Architect",
  "Marketing Assistant": "Marknadsassistent",
  "Portfolio Manager": "Portföljförvaltare",
  "Project Manager and Sustainability Manager":
    "Projektledare och hållbarhetsansvarig",
  Accountant: "Redovisningsekonom",
  "Risk Manager": "Risk Manager",
  "Systems Manager": "Systemförvaltare",
  "Director Business Development": "Tord Direktör affärsutveckling",
  "Treasury Controller": "Controller",
  CEO: "VD",

  "Search by portfolio report configuration":
    "Sök efter portföljrapportskonfiguration",
  "Add new portfolio report configuration":
    "Lägg till ny portföljrapportskonfiguration",
  "Portfolio reports": "Portföljrapporter",
  "Portfolio report configuration added":
    "Portföljrapportskonfiguration tillagd",
  "Adding portfolio report configuration failed":
    "Misslyckades med att skapa ny portföljrapportskonfiguration",
  "Portfolio report configuration deleted":
    "Portföljrapportskonfiguration borttagen",
  "Deleting portfolio report configuration failed":
    "Radering av portföljrapportskonfiguration misslyckades",
  "Portfolio report configuration updated":
    "Portföljrapportskonfiguration uppdaterad",
  "Updating portfolio report configuration failed":
    "Uppdatering av portföljrapportskonfiguration misslyckades",
  Portfolio: "Portfölj",
  Portfolios: "Portföljer",
  "Confirm name to delete": "Bekräfta namn för att radera",
  "The names do not match": "Namnen matchar inte",
  "Configure portfolio reports": "Konfigurera portföljrapporter",
  "All products": "Alla produkter",
  "All portfolios": "Alla portföljer",
  "Exchange Rate": "Valutakurs",
  Summary: "Summering",

  "This name is already taken and it has to be unique":
    "Namnet måste vara unikt",
  NetMW: "NetMW",
  "Forecast Volume": "Prognosvolym",

  Productname: "Produktnamn",
  "Paste copied data from excel": "Klistra in data från excel",
  "Pasted data": "Inklistrad data",
  History: "Historik",
  "Missing headers for posted data": "Inklistrad data saknar rubriker",
  "Trade period": "Handelsperiod",
  "Start of trade period": "Start av handelsperiod",
  "Start of hedge period": "Start av hedge-period",
  "Forecast created": "Prognos skapad",
  "Faulty data pasted": "Felaktig data inklistrad",
  "Creating forecast failed": "Misslyckades att skapa prognos",
  "Mandate created": "Mandat skapad",
  "Creating mandate failed":
    "Misslyckades att skapa mandat! Kontrollera att endast procentvärden anges.",
  "Forecast History": "Prognoshistorik",
  "Forecast deleted": "Prognos borttagen",
  "Delete forecast failed": "Borttagning av prognos misslyckades",
  "Mandate History": "Mandathistorik",
  "Mandate deleted": "Mandat borttagen",
  "Delete mandate failed": "Borttagning av mandat misslyckades",
  Year: "År",
  "Configure forecast, mandate and import position":
    "Konfigurera prognos, mandat och importera position",
  "Portfolio configuration": "Portföljkonfiguration",
  Mandate: "Mandat",
  "Import Position": "Importera position",
  Date: "Datum",

  "No products where found": "Inga produkter hittades",
  "There is nothing to show": "Det finns ingen data att visa",
  Approved: "Godkänd",
  "Belongs to third party": "Tillhör tredje part",
  "User Agreement": "Allmänna villkor",

  "Select all": "Markera alla",
  "Unselect all": "Avmarkera alla",

  Tomorrow: "I morgon",
  Today: "Idag",
  Market: "Marknad",
  Weather: "Väder",
  "System Price": "Systempris",
  "Spot and forward prices": "Spot och terminspriser",
  Prices: "Priser",
  "Spot prices": "Spotpriser",

  "Target volume": "Målvolym",
  "Delivery period": "Leveransperiod",
  Delivery: "Leverans",
  Target: "Mål",
  Hedged: "Säkrat",
  Start: "Start",
  End: "Slut",
  "Index orders": "Indexordrar",
  Buy: "Köp",
  Sell: "Sälj",
  Unknown: "Okänd",
  Upcoming: "Kommande",
  New: "Ny",
  Hedging: "Prissätts",
  Awaiting: "Avvaktar",
  Completed: "Säkrad",
  Terminated: "Avbruten",
  "Until further notice": "Tillsvidare",
  "Financial Price per unit": "Finansiellt á pris",
  "Third Party Portfolios": "Tredjepartsportföljer",
  "Third party client": "Tredjepartskund",
  Actor: "Aktör",
  Export: "Exportera",
  rows: "rader",
  "Remove rows": "Ta bort rader",
  "Successfully saved import positions": "Lyckades spara Importerad position",
  Guide: "Guide",

  "Key Management": "Nyckelhantering",
  "This is the only time you can copy the key, please copy it now and save it in a safe place.":
    "Detta är den enda tillfället du får chans att kopiera api-nyckeln, kopiera den och spara den på ett säkert ställe.",
  "API-Keys": "API Nycklar",
  "Generate new key": "Skapa ny API nyckel",
  "Your new API-key": "Din nya API nyckel",
  "Copied API key": "Kopierade API nyckeln",
  "Enter key name": "Ange namn för nyckeln",
  Revoke: "Återkalla",
  ConfirmRevoke: "Är du säker på att du vill återkalla nyckeln?",
  Create: "Skapa",

  Authentication: "Autentisering",
  Limitations: "Begränsningar",
  "Another option to try out the API is to use curl:":
    "Ett annat alternativ är att du testar med hjälp av ett curl-anrop:",
  "Getting started with Modity REST API": "Kom igång med Modity REST API",
  'An API Key is required to use the API. You can create a key by going to the "Key management" tab and clicking on the "Create new API key" button.':
    'En API-Nyckel krävs för att kunna använda sig utav API:et. Nyckeln kan du skapa genom att gå till fliken "Nyckelhantering" och klicka på knappen "Skapa ny API-nyckel".',
  "Note that you must copy and save the key in a safe place immediately as it cannot be seen again after the popup has been closed. If you lose the key, you will need to create a new one.":
    "Observera att du måste kopiera och spara nyckeln på ett säkert ställe då man inte kan se den igen efter att rutan stängts ner. Om du skulle tappa bort nyckeln behöver du skapa en ny.",
  'A key has an expiry date connected to it, which you can see by going to the "Key Management" tab and expanding the "Current Key" pane. Here you can also revoke the key. If you create a new key the old key will become invalid, effective immediately.':
    'En nyckel har en giltighetstid som man kan se genom att gå in på "Nyckelhanterings"-tabben och expandera "Nuvarande Nyckel"-rutan. Här kan man även återkalla sin nyckel. Om du skapar en ny nyckel kommer den gamla att bli återkallad direkt.',
  "The API is documented using Swagger":
    "API:et är dokumenterat med hjälp av Swagger",
  'Using Swagger, you can try out the API as a developer. The endpoints require the API key and those parameters that are marked as "required".':
    'Med Swagger kan du som utvecklare prova att hämta data från API:et. Det krävs att du skickar med din API-nyckel och de parametrar som står som "required".',
  "The API is limited to receiving a maximum of 100 calls / minute per user.":
    "API:et är begränsat till att ta emot max 100 anrop / minut per användare.",

  "This website uses cookies": "Denna hemsida använder cookies",
  "The information is used to improve our services. Read more in our":
    "Informationen används för att förbättra våra tjänster. Läs mer i vår",
  "Cookie Policy": "Cookiepolicy",

  Accept: "Acceptera",
  "Horizon counterparties": "Horizon motparter",
  "Electricity Certificates": "Elcertifikat",

  forecast: "Prognos",
  buyQuantity: "Antal köpta",
  buyPriceAvg: "Pris per köpt",
  sellQuantity: "Antal sålda",
  sellPriceAvg: "Pris per såld",
  totalQuantity: "Totalt antal",
  totalPriceAvg: "Snittpris",
  totalPrice: "Kostnad",
  quotaPercent: "Kvotplikt %",
  quotaQuantity: "Behov enligt prognos",
  buyForecastPercent: "Köpt antal/prognos %",
  sellForecastPercent: "Sålt antal/prognos %",
  importedQuantity: "Totalt importerad position antal",
  importedPriceAvg: "Totalt importerad position pris",
  remainingQuantity: "Kvarstående behov antal",
  remainingPercent: "Kvarstående behov %",
  importedSellQuantity: "Sålda importerade positioner antal",
  importedBuyQuantity: "Köpta importerade positioner antal",
  importedSellPriceAvg: "Sålda Importerade positioner pris",
  importedBuyPriceAvg: "Köpta Importerade positioner pris",
  "Electricity certificate": "Elcertifikat",
  "ELC service": "ELC-tjänst",
  Quantity: "Mängd",

  quotaObligationVolume: "Kvotpliktig volym",
  quotaObligationExemptedVolume: "Kvotpliktsbefriad volym",
  consumptionVolume: "Förbrukningsvolym",
  Until: "Till",
  apiTokenWarning: "Obs: Användaren har en aktiv API-nyckel!",

  Resolution: "Upplösning",
  Yearly: "År",
  Quarterly: "Kvartal",
  Monthly: "Månad",
  "There was an error trying to get the report":
    "Misslyckades att hämta rapport",
  "Adding email whitelist domain failed":
    "Tilläggning av e-postdomän misslyckades",
  "Deleting email whitelist domain failed":
    "Borttagning av e-postdomän misslyckades",
  "Email domain whitelist": "Tillåtna e-postdomäner",
  emailDomainInputLabel: "E-postdomän, t. ex. modity.se",
  "No whitelisted email domains": "Tillåtna e-postdomäner saknas",

  "Index product": "Indexprodukt",
  "Index pool": "Indexpool",
  IndexPool: "Indexpool",
  IntroductionOrder: "Introduktionsorder",
  "Select configuration type": "Välj konfigurationstyp",
  notFound: "Vi kunde inte hitta det du söker :(",

  INDEXPOOL_OVERVIEW: "Översikt",
  INDEXPOOL_ORDERS: "Ordrar",
  INDEXPOOL_INVOICES: "Fakturaunderlag",
  Orders: "Ordrar",
  Key: "Nyckel",
  IndexProductPortfolios: "Indexportföljer",
  IndexProductPortfolios_limit: "de som redan används på kund är ej valbara",

  "Delivery month": "Leveransmånad",
  "First delivery month": "Första leveransmånad",
  Heading_SpotPrice_invoice_material: "Avräkningspris",
  "Index pools": "Indexpooler",
  "No available options": "Inga tillgängliga val",
  "Index Portfolios": "Indexportföljer",

  GREEN_PRODUCTS_ELC: "Elcertifikat - rapport",
  GREEN_PRODUCTS_GO_SUMMARY: "Ursprungsgarantier - summering",
  GREEN_PRODUCTS_GO: "Ursprungsgarantier - rapport",
  GREEN_PRODUCTS_GO_CONSUMPTION_FORM: "Indata månadsförbrukning",
  Preliminary: "Preliminär",
  Final: "Slutgiltig",
  "Are you sure you want to save?": "Är du säker på att du vill spara?",
  "You can't undo this choice": "Det går inte att ångra det här valet",
  "Update consumption failed": "Uppdatering av förbrukning misslyckades",
  "Consumption updated": "Uppdatering av förbrukning lyckades",
  TOTAL_UNIT_PRICE: "Totalt pris per UG",
  TOTAL_QUANTITY: "Totalt antal UG",
  TOTAL_PRICE: "Totalt pris",
  Amount: "Antal",
  "GO service": "UG-tjänst",
  "Guarantee of origin": "Ursprungsgarantier",

  Files: "Filer",
  TABS_INVOICES: "Fakturor",
  TABS_DOCUMENTS: "Dokument",
  TABS_INVOICE_MATERIALS: "Fakturaunderlag",
  "No documents found": "Inga dokument hittades",
  Everyone: "Alla",
  Upload: "Ladda upp",
  "Accessible to everyone": "Tillgänglig för alla organisationer i Live",
  "Upload succeeded": "Uppladdning lyckades",
  "Upload failed": "Uppladdning misslyckades",
  "Document deleted": "Dokument borttaget",
  "Failed to delete document": "Borttagning av dokument misslyckades",
  "No invoice material found": "Inget fakturaunderlag hittades",

  "Time zone": "Tidszon",
  "Availability plan": "Tillgänglighetsplan",
  "Availability plan template": "Mall för tillgänglighetsplan",
  AVAILABILITY_PLAN_TEMPLATE_None: "Ingen",
  AVAILABILITY_PLAN_TEMPLATE_TimeSeriesPlant: "Tidsserie",
  Energy_OVERVIEW: "Översikt",
  Energy_PLANT: "Anläggning",
  Energy_AVAILABILITY: "Tillgänglighet",

  AvailabilityPlanDownloadInstructions:
    "Ladda ner din mall, fyll i, ladda upp och verifiera innan du sparar",
  "Please upload a document":
    "Ladda upp en ifylld mall för att kunna verifiera",
  Errors: "Fel",
  Verification: "Verifiering",
  "Download Template": "Ladda ner mall",
  AvailabilityPlanCriticalErrorMessage:
    "Något gick fel vid verifieringen, var god försök igen eller kontakta Modity",
  "Successfully saved Availability plan":
    "Lyckades spara tillgänglighetsplanen",
  "Saving availability plan failed":
    "Det gick inte att spara tillgänglighetsplanen",
  Found: "Hittade",
  "in sheet": "i flik",
  "at cell": "i cell",
  row: "rad",
  column: "kolumn",
  expected: "förväntat",

  preliminaryVolumeInformation: `Den preliminära förbrukningen måste fyllas i och sparas för att låsa upp sektionen "Slutgiltig"`,
  finalVolumeInformation: "Den slutgiltiga förbrukningen",
  "Total volume": "Total volym",
  "Fixed volume": "Fast volym",
  "Preliminary consumption volume": "Preliminär förbrukningsvolym",
  "Final consumption volume": "Slutgiltig förbrukningsvolym",

  availabilityWeekError: "Måste vara mellan 1 och 52",
  availabilityDayError: "Måste vara mellan 1 och 365",
  "Amount of weeks": "Antal veckor",
  "Amount of days": "Antal dagar",
  downloadTemplateDialogTitle: "Hur många dagar vill du ha i mallen?",
  Range: "Intervall",
  Continues: "Framåt",
  "Reset filter": "Återställ filter",
  Filter: "Filter",
  Visual: "Visuellt",

  "Copied chart": "Kopierade grafen",

  "Add new period": "Lägg till ny period",
  AddPeriodDescription: "Välj den nya periodens startdatum:",
  "Can only delete latest period": "Kan bara ta bort senaste perioden",
  "Successfully updated factors": "Uppdaterade avgifter",
  "Could not update factors": "Det gick inte att uppdatera avgifter",
  "Succesfully deleted period": "Period borttagen",
  "Could not delete period": "Det gick inte att ta bort period",
  "Succesfully added a new period": "Perioden har lagts till",
  "Could not add a new period": "Det gick inte att lägga till period",

  feeLabel_PowerReserve: "Effektreservavgift",
  feeLabel_NordpoolConsumption: "Nordpool avgift förbrukning",
  feeLabel_NordpoolProduction: "Nordpool avgift produktion",
  feeLabel_EdielConsumption: "Ediel avgift förbrukning",
  feeLabel_EdielProduction: "Ediel avgift produktion",
  feeLabel_ImbalanceConsumption: "Obalansavgift förbrukning",
  feeLabel_ImbalanceProductionOther: "Obalansavgift produktion övrigt",
  feeLabel_ImbalanceProductionSolar: "Obalansavgift produktion sol",
  feeLabel_ImbalanceProductionWind: "Obalansavgift produktion vind",
  feeLabel_ImbalanceProductionHydro: "Obalansavgift produktion vatten",
  feeLabel_ImbalanceProductionCogeneration:
    "Obalansavgift produktion kraftvärme",
  feeLabel_BalanceFeeConsumptionSE1: "Arvode förbrukning SE1",
  feeLabel_BalanceFeeConsumptionSE2: "Arvode förbrukning SE2",
  feeLabel_BalanceFeeConsumptionSE3: "Arvode förbrukning SE3",
  feeLabel_BalanceFeeConsumptionSE4: "Arvode förbrukning SE4",
  feeLabel_BalanceFeeProductionOther: "Arvode produktion övrigt",
  feeLabel_BalanceFeeProductionSolar: "Arvode produktion sol",
  feeLabel_BalanceFeeProductionWind: "Arvode produktion vind",
  feeLabel_BalanceFeeProductionHydro: "Arvode produktion vatten",
  feeLabel_BalanceFeeProductionCogeneration: "Arvode produktion kraftvärme",
  feeLabel_MonthlyFee: "Månadsavgift",
  feeLabel_InterestRate: "Stibor",
  feeLabel_Production: "Grundavgift produktion",
  feeLabel_Consumption: "Grundavgift förbrukning",
  imbalanceConsumptionDescription: "1 = avgift, <> 1 = avgift - 0,5",
  feeLabel_InterestRateProductionAddition: "Räntetillägg produktion",
  feeLabel_InterestRateProductionDays: "Räntetillägg produktion dagar",
  feeLabel_InterestRateConsumptionAddition: "Räntetillägg förbrukning",
  feeLabel_InterestRateConsumptionDays: "Räntetillägg förbrukning dagar",
  feeLabel_InterestRate3m: "Stibor (3m)",
  interestRateDescription: "1 = aktiv, 0 = inaktiv",

  Hydro: "Vatten",
  Solar: "Sol",
  Wind: "Vind",
  Bio: "Bio",
  Nuclear: "Kärnkraft",
  GecWind: "BM vind",
  GecSolar: "BM sol",
  GecHydro: "BM vatten",
  GecBio: "BM Bio",
  Organization: "Organisation",
  ImpersonatedRole: "Tillfällig roll",
  EndImpersonation: "Avsluta tillfällig roll",
  UseImpersonatedRole: "Använd tillfällig roll",

  "Production Types": "Produktionstyper",
  Ongoing: "Löpande",
  value: "värde",
  "invalid date format": "ogiltigt datumformat",
  "invalid number value": "ogiltigt numerisk värde",
  "error message": "felmeddelande"
};
